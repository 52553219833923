.BLoginBox {
  align-self: center;
  margin-top: 40px;
  border: 1px solid;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 210px;
}

.BLoginBrandLogo {
  border: 1px solid;
  display: flex;
  width: 180px;
  height: 180px;
  border-radius: 90px;
  margin-bottom: 20px;
  align-self: center;
}

.BLoginTextboxCaption {
  font-family: "Baskerville";
  font-size: 20px;
}

.BLoginTextbox {
  align-self: center;
  font-family: "Baskerville";
  font-size: 20px;
  margin-bottom: 14px;
  width: 200px;
}

.BLoginErrorMessage {
  font-family: "Baskerville";
  font-size: 18px;
  padding-top: 20px;
}
