.CustomersTitle {
  margin-top: 20px;
  font-family: "Baskerville";
  font-size: 26px;
}

.CustomersSubtitleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.CustomersFiltersView {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  overflow-x: auto;
}

.CustomersFilter {
  margin-right: 10px;
  font-family: "Baskerville";
  font-size: 18px;
}

.CustomersCount {
  font-family: "Baskerville";
  font-size: 22px;
  flex-shrink: 0;
  margin-left: 12px;
}

.CustomersList {
}

.CustomersSingle {
  border: 1px solid;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
}

.CustomersSingle:hover {
  background-color: lightgray;
}

.CustomersSingleDemographics {
  flex: 1;
}

.CustomersSingleDemographicsGrid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.CustomersSingleTitle {
  font-family: "Baskerville";
  font-size: 22px;
  margin-bottom: 6px;
}

.CustomersSingleDemographicsColumn {
  margin-right: 10px;
}

.CustomersSingleDemographic {
  font-family: "Baskerville";
  font-size: 16px;
  margin-top: 10px;
}

.CustomersSingleProducts {
  width: 50%;
  display: flex;
  flex-direction: row;
}

.CustomersProduct {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CustomersProductImage {
  border: 1px solid gray;
  aspect-ratio: 1;
  object-fit: cover;
  width: 90%;
}
