.BPTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 14px;
}

.BPTitle {
  font-family: "Baskerville";
  font-size: 26px;
}

.BPButtons {
  display: flex;
  flex-direction: row;
}

.BPDeleteButton {
  font-family: "Baskerville";
  font-size: 20px;
  border: 1px solid;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 10px;
  padding-top: 2px;
  cursor: pointer;
}

.BPSaveButton {
  font-family: "Baskerville";
  font-size: 20px;
  border: 1px solid;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  cursor: pointer;
}

.BPSaveButtonDisabled {
  font-family: "Baskerville";
  font-size: 20px;
  color: lightgray;
  border: 1px solid lightgray;
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
}

.BPBody {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}

.BPAttributesBar {
  border: 1px solid;
  min-width: 200px;
  min-height: 500px;
  padding-bottom: 30px;
  overflow-y: auto;
  max-height: 550px;
}

.BPAttributesTitle {
  font-family: "Baskerville";
  font-size: 20px;
  margin-top: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.BPNewAttributeView {
  display: flex;
  flex-direction: row;
}

.BPNewAttribute {
  font-family: "Baskerville";
  font-size: 16px;
  border: 1px solid;
  border-radius: 4px;
  margin-left: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 6px;
  margin-bottom: 20px;
  cursor: pointer;
}

.BPAttributeType {
  font-family: "Baskerville";
  font-size: 18px;
  border-top: 1px solid;
  border-bottom: 1px solid;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  margin-bottom: 4px;
  margin-top: 4px;
  display: flex;
  flex-direction: row;
}

.BPCaretDown {
  margin-left: 10px;
  align-self: center;
}

.BPAttributeName {
  padding-left: 18px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.BPAttributeName:hover {
  background-color: lightgray;
}

.BPAttributeNameClicked {
  padding-left: 18px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: lightgray;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.BPSubAttributeName {
  padding-left: 28px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.BPSubAttributeName:hover {
  background-color: lightgray;
}

.BPSubAttributeNameClicked {
  padding-left: 28px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: lightgray;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.BPAttributeNameText {
  font-family: "Baskerville";
  font-size: 14px;
}

.BPAttributeNameTextRed {
  font-family: "Baskerville";
  font-size: 14px;
  color: red;
}

.BPDraftAttributeContainer {
  display: flex;
  flex-direction: row;
}

.BPDraftAttribute {
  font-family: "Baskerville";
  font-size: 14px;
  margin-left: 10px;
  margin-top: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  flex: 1;
  margin-right: 10px;
  margin-bottom: 6px;
}

.BPProductsNavigator {
  margin-left: 20px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.BPCurrentAttributeHeader {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.BPCurrentAttribute {
  font-family: "Baskerville";
  font-size: 20px;
}

.BPCurrentCount {
  font-family: "Baskerville";
  font-size: 16px;
  align-self: center;
}

.BPFilters {
  display: flex;
  flex-direction: row;
  padding-bottom: 8px;
}

.BPFiltersTitle {
  font-family: "Baskerville";
  font-size: 18px;
}

.BPFilterView {
  border: 1px solid;
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
  font-family: "Baskerville";
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.BPProductGrid {
  border: 1px solid;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  min-height: 200px;
}

.BPProduct {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
}

.BPProduct:hover {
  background-color: lightgray;
}

.BPProductChecked {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: lightgray;
  justify-content: space-between;
}

.BPProductInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BPImage {
  border: 1px solid gray;
  width: 90%;
  aspect-ratio: 1;
  object-fit: cover;
  margin-bottom: 10px;
}

.BPProductCaption {
  font-family: "Baskerville";
  font-size: 14px;
  margin-bottom: 6px;
  text-align: center;
}

.BPProductAttributes {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.BPPaginator {
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.BPPaginatorText {
  font-family: "Baskerville";
  font-size: 14px;
  margin-right: 30px;
  margin-left: 30px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.BPPaginatorTextHidden {
  font-family: "Baskerville";
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 10px;
  margin-right: 10px;
  visibility: hidden;
}

.BPPaginatorTextLink {
  font-family: "Baskerville";
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.BPPaginatorTextLink:hover {
  background-color: lightgray;
}
