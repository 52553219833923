.RulesTitleRow {
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
}

.RulesTitle {
  font-family: "Baskerville";
  font-size: 26px;
}

.RulesCreateNewButton {
  font-family: "Baskerville";
  font-size: 22px;
  border: 1px solid;
  border-radius: 4px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 20px;
  cursor: pointer;
}

.RulesNew {
  padding-left: 10px;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  border: 1px solid;
  margin-bottom: 20px;
}

.RulesNewTitle {
  font-family: "Baskerville";
  font-size: 22px;
  margin-bottom: 10px;
}

.RulesNewBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.RulesNewCustomerSegment {
  margin-left: 10px;
  margin-right: 10px;
  flex: 1;
}

.RulesNewCustomerSegmentTitle {
  font-family: "Baskerville";
  font-size: 18px;
  margin-bottom: 6px;
}

.RulesNewSection {
  flex: 1;
  margin-right: 10px;
}

.RulesNewButtonsContainer {
  display: flex;
  flex-direction: column;
}

.RulesNewButtons {
  display: flex;
  flex-direction: row;
}

.RulesNewSave {
  font-family: "Baskerville";
  font-size: 18px;
  border: 1px solid;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 10px;
  cursor: pointer;
}

.RulesNewSaveDisabled {
  font-family: "Baskerville";
  font-size: 18px;
  border: 1px solid lightgray;
  color: lightgray;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 10px;
}

.RulesNewCancel {
  font-family: "Baskerville";
  font-size: 18px;
  border: 1px solid;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  cursor: pointer;
}

.RulesCheckboxOption {
  display: flex;
  flex-direction: row;
}

.RulesCheckboxOptionText {
  margin-left: 4px;
}

.RulesLinkOption {
  cursor: pointer;
}

.RulesNormalOption {
}

.RulesValueContainer {
  display: flex;
  flex-direction: row;
}

.RulesHideValueContainerChildren {
  width: 0px;
}

.RulesValueContainerDefault {
  color: gray;
}

.RulesValueContainerValue {
}

.RulesAll {
  margin-bottom: 20px;
  margin-top: 20px;
}

.RulesAllHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10px;
  border-bottom: 2px solid;
  padding-bottom: 10px;
}

.RulesItemColumns {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.RulesSectionHeader {
  font-family: "Baskerville-semibold";
  font-size: 20px;
  width: 50%;
}

.RulesSegmentHeader {
  font-family: "Baskerville-semibold";
  font-size: 20px;
  width: 50%;
}

.RulesCheckboxHeader {
  font-family: "Baskerville-semibold";
  font-size: 20px;
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.RulesItem {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  margin-left: 10px;
  border-bottom: 1px solid;
  padding-bottom: 10px;
  justify-content: space-between;
}

.RulesSection {
  width: 50%;
  display: flex;
  flex-direction: row;
}

.RulesSegment {
  width: 50%;
  display: flex;
  flex-direction: row;
}

.RulesSegmentText {
  font-family: "Baskerville";
  font-size: 20px;
  cursor: pointer;
  margin-right: 10px;
}

.RulesCheckbox {
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
