.AnalyticsDemographicsContainer {
  margin-top: 20px;
  background-color: #e5f9f880;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid;
  border-radius: 4px;
}

.AnalyticsDemographicsTitle {
  font-family: "Baskerville";
  font-size: 22px;
  margin-left: 20px;
}

.AnalyticsDemographicsCharts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.AnalyticsChart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.AnalyticsChartTitle {
  font-family: "Baskerville";
  font-size: 20px;
}

.AnalyticsDrilldownContainer {
  border: 1px solid;
  border-radius: 4px;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #F9CE7A40;
  margin-bottom: 20px;
}

.AnalyticsDrilldownHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 30px;
  margin-left: 20px;
  margin-bottom: 24px;
}

.AnalyticsDrilldownTitle {
  font-family: "Baskerville";
  font-size: 22px;
}

.AnalyticsDrilldownHeaderText {
  font-family: "Baskerville";
  font-size: 18px;
  align-self: flex-end;
  flex-shrink: 0;
  display: flex;
  cursor: pointer;
}

.AnalyticsForwardArrow {
  margin-left: 6px;
  align-self: center;
}

.AnalyticsDrilldownBody {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
}

.AnalyticsDrilldownTopAttributes {
  display: flex;
  flex-direction: column;
  width: 25%;
}

.AnalyticsDrilldownAttribute {
  font-family: "Baskerville";
  font-size: 14;
  margin-bottom: 8px;
}

.AnalyticsDrilldownProducts {
  flex: 1;
  margin-left: 20px;
}

.AnalyticsDrilldownProductsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 6px;
  margin-bottom: 10px;
}

.AnalyticsDrilldownProductsTitle {
  font-family: "Baskerville";
  font-size: 18px;
  display: flex;
}

.AnalyticsDrilldownProductsCustomizeButton {
  font-family: "Baskerville";
  font-size: 18px;
  display: flex;
  cursor: pointer;
}

.AnalyticsProductRow {
  display: flex;
  flex-direction: row;
}

.AnalyticsProduct {
  flex: 1;
  align-items: center;
  flex-direction: column;
  display: flex;
  max-width: 20%;
}

.AnalyticsProductImage {
  aspect-ratio: 1;
  width: 90%;
  object-fit: cover;
}
