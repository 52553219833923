.ProfileHeader {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.ProfilePictureView {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  min-width: 350px;
  margin-top: 20px;
}

.ProfilePicture {
  width: 60%;
  border: 1px solid gray;
  aspect-ratio: 1;
  border-radius: 50%;
  max-width: 300px;
}

.ProfileStats {
  display: flex;
  flex-direction: row;
  flex: 1;
  min-width: 350px;
  justify-content: center;
  margin-top: 14px;
  margin-bottom: 14px;
}

.ProfileStatView {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.ProfileStatNumber {
  font-family: "Baskerville";
  font-size: 30px;
}

.ProfileStatCaption {
  font-family: "Baskerville";
  font-size: 16px;
  margin-top: 4px;
}

.ProfileSection {
  margin-top: 10px;
  margin-bottom: 10px;
}

.ProfileSection:hover {
  background-color: whitesmoke;
}

.ProfileSection:hover .ProfileClosetSeeAll {
  text-decoration: underline;
}
.ProfileSection:hover .ProfileClosetTitle {
  text-decoration: underline;
}

.ProfileClosetTitleBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4px;
}

.ProfileClosetTitle {
  font-family: "Baskerville";
  font-size: 20px;
  margin-left: 10px;
}

.ProfileClosetSeeAll {
  font-family: "Baskerville";
  font-size: 16px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.ProfileClosetSeeAllIcon {
  margin-left: 4px;
  margin-bottom: 1px;
}

.ProfileClosetPreview {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.ProfileImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
}

.ProfileImage {
  border: 1px solid gray;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 95%;
  aspect-ratio: 1;
  object-fit: cover;
}

.ProfileImagePlaceholder {
  margin-top: 11px;
  margin-bottom: 11px;
  margin-left: 1px;
  margin-right: 1px;
  width: 95%;
  aspect-ratio: 1;
}

.ProfileSectionNoItems {
  font-family: "Baskerville";
  font-size: 16px;
  margin-left: 40px;
  margin-top: 30px;
  margin-bottom: 30px;
}
