.WishlistContainerVertical {
  display: flex;
  flex-direction: column;
}

.WishlistContainerHorizontal {
  display: flex;
  justify-content: center;
}

.WishlistContainerMaxWidth {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 920px;
  padding-left: 10px;
  padding-right: 10px;
}

.WishlistHeaderMaxWidth {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  max-width: 920px;
  padding-left: 10px;
  padding-right: 10px;
}

.WishlistHeader {
  border-bottom: 1px solid black;
  display: flex;
  padding-top: 6px;
  padding-bottom: 4px;
  position: sticky;
  top: 0px;
  background-color: white;
  justify-content: center;
  z-index: 100;
}

.WishlistLogo {
  font-family: "Baskerville-italic";
  font-size: 30px;
  padding-left: 10px;
}

.WishlistAppButton {
  padding-right: 10px;
}

.WishlistTitle {
  display: flex;
  align-self: center;
  font-family: "Baskerville";
  font-size: 24px;
  margin-top: 10px;
}

.WishlistItemCount {
  font-family: "Baskerville";
  font-size: 20px;
  margin-top: 10px;
  display: flex;
  align-self: center;
}

.WishlistItemGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.WishlistItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33%;
  min-width: 175px;
}

.WishlistImage {
  border: 1px solid gray;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 95%;
  aspect-ratio: 1;
  object-fit: cover;
}

.WishlistItem:hover {
  background-color: whitesmoke;
}

.WishlistItemTitle {
  font-family: "Baskerville";
  font-size: 18px;
  text-align: center;
}

a:link {
  color: black;
  text-decoration: none;
}

a:visited {
  color: black;
  text-decoration: none;
}

.WishlistItemSubtitle {
  font-family: "Baskerville";
  font-size: 16px;
  text-align: center;
  margin-top: 8px;
}

.WishlistCaption {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.WishlistCaption:hover {
  text-decoration: underline;
}
