.DashboardHeaderMaxWidth {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1170px;
  padding-left: 10px;
  padding-right: 10px;
}

.DashboardHeaderButton {
  font-family: "Baskerville";
  font-size: 18px;
  align-self: center;
  margin-left: 10px;
  margin-right: 10px;
}

.DashboardTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.DashboardTitle {
  font-family: "Baskerville";
  font-size: 26px;
  padding-left: 10px;
  padding-top: 10px;
}

.DashboardPauseButton {
  margin-left: 14px;
  margin-right: 8px;
}

.DashboardLiveButton {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.DashboardLiveButtonText {
  font-family: "Baskerville";
  font-size: 18px;
  color: red;
  margin-left: 6px;
  align-self: center;
}

.DashboardLiveDot {
  font-size: 18px;
  color: red;
}

.DashboardTopContainer {
  border: 1px solid;
  border-radius: 4px;
  margin-top: 10px;
  padding-bottom: 10px;
  background-color: #e5f9f880;
}

.DashboardDemographicsContainer {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.DashboardDemographicsTitle {
  font-family: "Baskerville";
  font-size: 22px;
  padding-left: 10px;
  padding-bottom: 10px;
}

.DashboardDemographicsGrid {
  display: flex;
  flex-direction: row;
}

.DashboardBehaviorGrid {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.DashboardDemographicsColumn {
  width: 33%;
}

.DashboardBehaviorColumn {
  width: 50%;
}

.DashboardDemographicsItem {
  font-family: "Baskerville";
  font-size: 16px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  white-space: pre;
}

.DashboardBoldText {
  font-weight: 600;
}

.DashboardProductsContainer {
  display: flex;
  flex-direction: column;
  padding-top: 14px;
  padding-bottom: 10px;
}

.DashboardCurrentlyViewingProducts {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.DashboardCurrentlyViewingTopRow {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  align-items: flex-end;
}

.DashboardCurrentlyViewingBottomRow {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
}

.DashboardProduct {
  display: flex;
  width: 20%;
  flex-direction: column;
  align-items: center;
}

.DashboardImage {
  border: 1px solid gray;
  margin-bottom: 10px;
  width: 95%;
  aspect-ratio: 1;
  object-fit: cover;
}

.DashboardProductCaption {
  font-family: "Baskerville";
  font-size: 12px;
  text-align: center;
  margin-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
}

.DashboardLikelihoodCaption {
  font-family: "Baskerville";
  font-size: 12px;
  text-align: center;
  margin-bottom: 4px;
  padding-left: 10px;
  padding-right: 10px;
}

.DashboardSidebarContainer {
  display: flex;
  flex: 1;
  max-width: 250px;
  flex-direction: column;
}

.DashboardSidebar {
  border: solid;
  border-radius: 4px;
  display: flex;
  flex: 0;
  margin-top: 10px;
  padding-top: 10px;
  margin-bottom: 20px;
  padding-bottom: 10px;
  flex-direction: column;
}

.DashboardActivityLogTitle {
  font-family: "Baskerville";
  font-size: 22px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
}

.DashboardActivityLog {
  overflow: auto;
  max-height: 800px;
  min-height: 500px;
}

.DashboardActivityItem {
  font-family: "Baskerville";
  font-size: 16px;
  border-top: solid gray;
  border-bottom: solid gray;
  flex: 1;
  padding-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 10px;
  margin-top: 14px;
  background-color: #F9CE7A40;
}
