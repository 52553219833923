.ListSubtitle {
  display: flex;
  align-self: center;
  font-family: "Baskerville";
  font-size: 20px;
  margin-top: 10px;
}

.ListDescription {
  display: flex;
  font-family: "Baskerville";
  font-size: 18px;
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.ListItemCount {
  font-family: "Baskerville";
  font-size: 20px;
  margin-top: 10px;
  display: flex;
  padding-left: 10px;
}
